var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-2" },
    [
      _c("masthead", {
        attrs: {
          title: "New York State Publications",
          datasource: _vm.datasource,
        },
      }),
      _vm.loaded
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("span", { staticClass: "mb-2" }, [
                    _vm._v("Select an existing company in your account"),
                  ]),
                  _c("br"),
                  _c("b-form-select", {
                    staticClass: "company-select",
                    attrs: {
                      "data-cy": "companySelect",
                      options: _vm.selectData,
                    },
                    on: { change: _vm.companySelected },
                    model: {
                      value: _vm.selectedCompany,
                      callback: function ($$v) {
                        _vm.selectedCompany = $$v
                      },
                      expression: "selectedCompany",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loaded
        ? _c(
            "b-row",
            { staticClass: "d-flex justify-content-center mb-3" },
            [
              _c("ct-centered-spinner", {
                attrs: { "data-cy": "busyIndicator" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }