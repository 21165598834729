<template>
  <b-container class="mt-2">
    <masthead title="New York State Publications" :datasource="datasource" />
    <b-row v-if="loaded">
      <b-col>
        <span class="mb-2">Select an existing company in your account</span>
        <br>
        <b-form-select
          v-model="selectedCompany"
          class="company-select"
          data-cy="companySelect"
          :options="selectData"
          @change="companySelected"
        />
      </b-col>
    </b-row>
    <b-row v-if="!loaded" class="d-flex justify-content-center mb-3">
      <ct-centered-spinner data-cy="busyIndicator" />
    </b-row>
  </b-container>
</template>

<script>
import { usStates } from '@/common/modules/usStates'
import Masthead from '@/components/shared/Masthead'
import axiosClient from '../../http'
import CtCenteredSpinner from '../../components/shared/CtCenteredSpinner'

export default {
  name: 'CompanySelection',
  components: {
    Masthead,
    CtCenteredSpinner,
  },
  data() {
    return {
      selectedCompany: null,
      datasource: null,
      loaded: false,
      companies: {},
      jurisdictionId: usStates.filter(j => j.abbreviation === 'NY')[0].id,
      filingId: null,
      filingMethodId: null,
    }
  },
  computed: {
    selectData() {
      let result = []
      if (this.companies && this.companies.length > 0) {
        result = this.companies.map(company => {
          return { value: company, text: company.name }
        })
      }
      return result.flat()
    },
    usStates() {
      return usStates
    },
  },
  async mounted() {
    const response = await axiosClient.get('client/companies', {
        params: {
          with_new_york_service: true,
          entity_type_names: ['Limited Liability Company', 'Professional Limited Liability Company'],
        },
    })

    this.companies = response.data.result

    this.loaded = true
  },
  methods: {
    async companySelected() {
      this.loaded = false
      const filings = await this.$store.dispatch('companies/fetchCompanyFilings', {
        params: {
          jurisdiction: this.jurisdictionId,
          company: this.selectedCompany.id,
        },
      })

      if (
        this.selectedCompany.domestic_registration &&
        this.selectedCompany.domestic_registration.jurisdiction_id === this.jurisdictionId
      ) {
        this.filingId = filings.data.filter(f => f.locality === 'domestic' && f.description === 'Articles of Organization')[0].id
      } else {
        this.filingId = filings.data.filter(f => f.locality === 'foreign' && f.description === 'Application for Authority')[0].id
      }

      const filingMethods = await this.$store.dispatch('filingMethods/fetchFilingMethods', {
        params: {
          filingId: this.filingId,
        },
      })
      this.filingMethodId = filingMethods.data.filter(m => m.type === 'mail' && m.name === 'Standard')[0].id

      this.$router.push({
        name: 'resourceSelection',
        params: {
          companyId: this.selectedCompany.id,
          jurisdictionId: this.jurisdictionId,
          filingId: this.filingId,
          filingMethodId: this.filingMethodId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
